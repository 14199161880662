let swapOn404 = (e) => {
	if (e.detail.xhr.status === 404) {
		e.detail.shouldSwap = true;
		e.detail.isError = false;
	}
};

let detect_ios = () => {
	const is_ios = !!window.navigator.userAgent.match(/iPad|iPhone|iPod/i);
	if (is_ios) document.body.classList.add('is-ios');
};

let setActiveNavItem = () => {
	const path = window.location.pathname;
	const navLinks = document.querySelectorAll('#nav a, #nav-mobile a');
	//console.log(path);

	navLinks.forEach(link => {
		let href = link.getAttribute('href');
	
		if (href === '/') {
			if (href === path) link.classList.add('active');
			return; //no need for further checks
		}
		else if (path.startsWith(href)) {
			link.classList.add('active');
		}
	});
};

let toggleMobileNav = () => {
	let isOpen = document.querySelector('#nav-mobile').classList.contains('is-open');;

	if (isOpen) {
		closeMobileNav();
		isOpen = false;
	}
	else {
		document.querySelector('#nav-mobile').classList.add('is-open');
		document.body.style.overflowY = 'hidden';
		window.addEventListener('resize', closeMobileNavOnResize);
		isOpen = true;
	}
};

let closeMobileNav = () => {
	document.querySelector('#nav-mobile').classList.remove('is-open');
	document.body.style.overflowY = '';
	window.removeEventListener('resize', closeMobileNavOnResize);
};

let closeMobileNavOnResize = () => {
	if (window.innerWidth > 1000) closeMobileNav();
	//console.log(window.innerWidth);
};

let getHeaderHeight = () => {
	//if (window.innerWidth > 600 && window.innerWidth < 1200) {}
	let height = document.querySelector('#header').offsetHeight;
	document.documentElement.style.setProperty('--header-height', Math.round(height) + 'px');
};

let disabledFormButtons = () => {
	document.querySelectorAll('form').forEach(form => {
		let buttons = form.querySelectorAll('button[type="submit"][disabled], button[type="reset"][disabled]');

		buttons.forEach(btn => {
			let removeDisabledAttribute = (e) => {
				btn.removeAttribute('disabled');
				//console.log(e);
				form.removeEventListener('input', removeDisabledAttribute);
			};
	
			if (btn) {
				form.addEventListener('input', removeDisabledAttribute);
			}
		});
	});
};

let toggleDropdown = (e) => {
	document.querySelectorAll('.dropdown').forEach(dropdown => {
		const outsideClick = !dropdown.contains(e.target);
		//console.log({outsideClick}, e.target);

		if (outsideClick) {
			dropdown.classList.remove('is-open');
			return;
		}

		dropdown.classList.toggle('is-open');
	});
};

let shortcuts = (e) => {
	if (e.repeat) return;
	// console.log({code: e.code, key: e.key, keyCode: e.keyCode}, e);
	
	if (e.ctrlKey && e.shiftKey && e.code === 'KeyG') {
		console.log('🎉 Ctrl + Shift + G 🎉', e);
	}
};


document.addEventListener('htmx:beforeSwap', swapOn404);
document.addEventListener('DOMContentLoaded', detect_ios);
document.addEventListener('htmx:afterRequest', setActiveNavItem);
document.addEventListener('DOMContentLoaded', setActiveNavItem);
document.addEventListener('htmx:beforeSwap', closeMobileNav);
document.addEventListener('DOMContentLoaded', disabledFormButtons);
document.addEventListener('htmx:afterRequest', disabledFormButtons);
document.addEventListener('keydown', shortcuts);

window.addEventListener('load', getHeaderHeight);
window.addEventListener('orientationchange', getHeaderHeight);
window.addEventListener('resize', getHeaderHeight);
window.addEventListener('click', toggleDropdown);
